export const spacing = {
  xl: '192px',
  large: '96px',
  mobileTop: '72px',
  medium: '48px',
  smallMedium: '32px',
  regular: '24px',
  small: '16px',
  xsmall: '8px',
  xxsmall: '4px',
  menuHeight: '64px',
  fullNavHeightInclLangSelector: '80px',

  // These are not in the styleguide and should be replaced
  mega: '17.5rem', // 280px
  xxlarge: '9.5rem', // 152px
  xlarge: '6rem', // 96px
  block: '2rem', // 32px
  // xxsmall: '0.25rem',
  pagePadding: '30px',
  pagePaddingMobile: '20px',
} as const;

export type Spacing = keyof typeof spacing;
