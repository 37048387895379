import { createContext, useEffect, useState } from 'react';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { ApiSettings } from '@hultafors/shared/types';

import {
  Feature,
  FeatureToggleFragment,
  FooterFragment,
  GlobalFields,
  GlobalFragment,
  MenuTreeFragment,
} from '@hultafors/solidgear/types';

export interface GlobalContextValue
  extends Omit<GlobalFields, 'featureToggle'> {
  setGlobal(value: GlobalFields['global']): void;
  setAllMenuTrees(value: GlobalFields['allMenuTrees']): void;
  setFooter(value: GlobalFields['footer']): void;
  settings: ApiSettings;
  displayLanguageSelector?: boolean;
  isEnabled(feature: string): boolean;
}

export const GlobalContext = createContext<GlobalContextValue | null>(null);

export interface GlobalProviderProps {
  children?: React.ReactNode;
  value: GlobalFields;
  settings: ApiSettings;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({
  value,
  children,
  settings,
}) => {
  const [global, setGlobal] = useState<GlobalFragment>(value.global);
  const [allMenuTrees, setAllMenuTrees] = useState<MenuTreeFragment[]>(
    value.allMenuTrees,
  );
  const [footer, setFooter] = useState<FooterFragment>(value.footer);
  const [featureToggle] = useState<FeatureToggleFragment>(value.featureToggle);
  const [displayLanguageSelector, setDisplayLanguageSelector] = useState(false);
  const [appInsights, setAppInsights] = useState<ApplicationInsights>();

  useEffect(() => {
    if (!appInsights && process.env['APPINSIGHTS_INSTRUMENTATIONKEY']) {
      setAppInsights(
        new ApplicationInsights({
          config: {
            instrumentationKey: process.env['APPINSIGHTS_INSTRUMENTATIONKEY'],
          },
        }),
      );
    }
  }, [settings]);

  useEffect(() => {
    if (settings) {
      if (settings?.languages?.length) {
        setDisplayLanguageSelector(true);
      }
    }
    if (value.allMenuTrees) {
      setAllMenuTrees(value.allMenuTrees);
    }
    if (value.global) {
      setGlobal(value.global);
    }
    if (value.footer) {
      setFooter(value.footer);
    }
  }, [value, settings]);

  const isEnabled = (feature: Feature) => {
    return featureToggle?.[feature];
  };

  return (
    <GlobalContext.Provider
      value={{
        footer,
        setFooter,
        allMenuTrees,
        setAllMenuTrees,
        global,
        setGlobal,
        isEnabled,
        settings,
        displayLanguageSelector,
        maintenanceMode: value.maintenanceMode,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
